<template>
  <div>
    <t-modal
      name="gmap-popup-alt"
      variant="vehicleLocation"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      ref="vehicleLocation"
    >
      <header class="px-3 py-4">
        <div class="flex justify-between modal-header">
          <div class="w-full text-left">
            <div class="gm-title">{{ title }}</div>
            <div class="gm-subtitle">{{ subtitle }}</div>
          </div>

          <div class="gm-lat-lng-area" @click="copyToClipboard">
            <div>
              <div class="text-14px">
                <span class="font-medium text-14px text-oDark">Lat: </span>
                <span class="text-oGray overflow-clip" v-if="marker != null">
                  {{ marker.lat ? Number(marker.lat).toFixed(8) : '--' }}
                </span>
              </div>
              <div class="text-14px">
                <span class="font-medium text-oDark">Long: </span>
                <span class="text-oGray overflow-clip" v-if="marker != null">
                  {{ marker.lng ? Number(marker.lng).toFixed(8) : '--' }}
                </span>
              </div>
            </div>
            <div class="text-right cursor-pointer">
              <i class="far fa-copy" />
            </div>
          </div>
        </div>
      </header>

      <section class="w-full h-96">
        <gmap-map
          ref="map"
          :zoom="15"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100%;"
          @click="setMarker"
        >
          <gmap-marker
            v-if="marker"
            :position="marker"
            :draggable="false"
            :icon="getStyledMarkerIcon(vehicle.lock.power_level)"
            style="width: 30px; height: 30px;"
          />
          <!-- <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="center=m.position" /> -->
        </gmap-map>
      </section>
    </t-modal>

    <!-- Toast notification -->
    <div v-if="showToast" class="toast">
      {{ toastMessage }}
    </div>
  </div>
</template>


<script>
import CopyToClipboardMixin from '@/mixins/copyToClipboardMixin'
import VehicleFlagHelperMixin from '@/mixins/vehicleFlagHelperMixin'
import { EventBus } from '@/utils/EventBus'

export default {
  name: 'GoogleMapModalAlt',
  mixins: [CopyToClipboardMixin, VehicleFlagHelperMixin],
  props: {
    title: {
      type: [String, Number],
      default: 'Drag PIN to locate exact location',
    },
    subtitle: {
      type: [String, Number],
      default: '',
    },
    markerIsUpdatable: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getWidth() {
      if (screen.width < 1200) {
        return '800'
      }
      if ((screen.width > 1300) & (screen.width < 1600)) {
        return '1000'
      }
      return '1200'
    },
    getLatLngUrl() {
      return `https://www.google.com/maps/search/?api=1&query=${this.lat},${this.lng}`
    },
  },
  data() {
    return {
      zoom: 15,
      center: {
        lat: 10,
        lng: 10,
      },
      vehicle: null,
      marker: null,
      place: null,
      loc: null,
      lat: this.lats || '90.12',
      lng: this.lngs || '90.12',
      locationMode: false,
      showToast: false,
      toastMessage: '',
    }
  },
  mounted() {
    EventBus.$on('gmap-popup-alt', (vehicle) => {
      this.vehicle = vehicle
      this.loc = vehicle.location
      this.locationMode = false
      this.initGoogleMap()
    })

    EventBus.$on('gmap-popup-alt-location', (location) => {
      this.locationMode = true
      let [lat, lng] = location.split(',')
      this.lat = lat
      this.lng = lng
      this.setLatLngData()
      return
    })
  },
  methods: {
    initGoogleMap() {
      if (this.vehicle) {
        let [lat, lng] = this.vehicle.location.split(',')
        this.lat = lat
        this.lng = lng
        this.setLatLngData()
        return
      }

      if (this.location && this.location.lat && this.location.lng) {
        this.lat = this.location.lat
        this.lng = this.location.lng
        this.setLatLngData()
        return
      }
    },
    setLatLngData() {
      this.center = {
        lat: Number(this.lat),
        lng: Number(this.lng),
      }
      this.marker = {
        lat: Number(this.lat),
        lng: Number(this.lng),
      }
    },
    setMarker(info) {
      if (this.markerIsUpdatable) {
        this.marker = {
          lat: info.latLng.lat(),
          lng: info.latLng.lng(),
        }
      }
    },
    updateMarkerPosition(event) {
      this.marker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
    },
    getStyledMarkerIcon(batteryPercentage) {
      const markerIcon = this.createMarkerIcon(batteryPercentage)
      return markerIcon
    },
    createMarkerIcon(batteryPercentage) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const centerX = canvas.width / 2
      const centerY = canvas.height / 2
      const iconRadius = 20 // Radius of the circular progress bar
      const markerWidth = 10 // Width of the marker triangle
      const markerHeight = 10 // Height of the marker triangle
      const startAngle = -Math.PI / 2 // Start at the top
      const endAngle = (2 * Math.PI * batteryPercentage) / 100 + startAngle // Calculate the end angle based on battery percentage

      // Clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height)

      // Draw the marker triangle below the circular progress bar
      context.beginPath()
      context.moveTo(centerX - markerWidth / 2, centerY + iconRadius)
      context.lineTo(centerX + markerWidth / 2, centerY + iconRadius)
      context.lineTo(centerX, centerY + iconRadius + markerHeight)
      context.closePath()
      context.fillStyle = '#000' // Set the marker color
      context.fill()

      // Draw the white background circle
      context.beginPath()
      context.arc(centerX, centerY, iconRadius + 5, 0, 2 * Math.PI)
      context.fillStyle = '#000' // Set the background color to white
      context.fill()

      // Draw the outer circle
      context.beginPath()
      context.arc(centerX, centerY, iconRadius, 0, 2 * Math.PI)
      context.strokeStyle = '#fff'
      context.lineWidth = 5
      context.stroke()

      // Draw the progress arc
      context.beginPath()
      context.arc(centerX, centerY, iconRadius, startAngle, endAngle)
      context.strokeStyle = this.getPowerFillColor(
        Math.round(batteryPercentage)
      ) // Change color based on battery status
      context.lineWidth = 5
      context.stroke()

      // Display the battery percentage as text
      context.font = 'bold 14px Arial'
      context.fillStyle = 'white'
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      context.fillText(Math.round(batteryPercentage) + '%', centerX, centerY)

      return canvas.toDataURL() // Convert the canvas to a data URL for the icon
    },

    getPowerFillColor(powerLevel) {
      let color = ''
      if (powerLevel < 25) {
        color = '#ff0000'
      } else if (powerLevel >= 25 && powerLevel < 70) {
        color = '#ffaf00'
      } else if (powerLevel >= 70 && powerLevel <= 100) {
        color = '#0ac84b'
      }
      return color
    },
    copyToClipboard() {
      // using mixin
      this.copyContentToClipboard(this.getLatLngUrl, {
        important: true,
        title: 'Lat Long Copied',
        text: 'Lat & Long has been copied to your clipboard',
      });

      // Show toast after copying
      this.showToastMessage('Lat & Long has been copied to your clipboard');
      // Notify success after copying
      this.$notify({
        group: 'generic',
        type: 'success',
        title: 'Lat Long Copied',
        text: 'Lat & Long has been copied to your clipboard',
      });
    },
    showToastMessage(message) {
      // Set toast message and show toast
      this.toastMessage = message;
      this.showToast = true;
      // Hide toast after 3 seconds
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    },
    hideToast() {
      // Hide toast
      this.showToast = false;
    },
  },
}
</script>


<style lang="scss" scoped>
.drag {
  font-size: 16px;
  font-weight: bold;
  color: #2e2e39;
}

.gm-title {
  font-size: 19px;
  font-weight: bold;
  color: #2e2e39;
}

.gm-subtitle {
  font-size: 14px;
  font-weight: medium;
  @apply text-oLightGray;
}

.gm-lat-lng-area {
  width: 230px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d3d3d3;
  background-color: #f8f8f8;
  @apply flex justify-between items-center mt-1 text-left px-4 cursor-pointer;
}

/* Toast notification styles */
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}
</style>


